import React, { useContext, useEffect, useState } from 'react'
import PopupModal from '../modals/PopupModal';
import Utils from '../../services/utils';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const DownloadAppPopup = observer(() => {
    const {app} = useContext(Context);

    const [visible, setVisible] = useState();

    const [show, setShow] = useState();

    const [appStoreLink, setAppStoreLink] = useState();
    const [googlePlayLink, setGooglePlayLink] = useState();
    
    useEffect(() => {
        if(app.settings){
            setAppStoreLink(app.settings.find(it => it.type === "app-store-link")?.value);
            setGooglePlayLink(app.settings.find(it => it.type === "google-play-link")?.value);
        }
    }, [app.settings])

    useEffect(() => {
        if(!Utils.getCookie('popup-modal')){
            setVisible(true);
        }
    }, [])

    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [installable, setInstallable] = useState(false);
  
    useEffect(() => {
      window.addEventListener('beforeinstallprompt', (e) => {
        //e.preventDefault();
        setDeferredPrompt(e);
        setInstallable(true);
      });
  
      return () => {
        window.removeEventListener('beforeinstallprompt', () => {});
      };
    }, []);

    const handleClose = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        Utils.setCookie('popup-modal', 'true', {secure: true, 'max-age': 86400});
        setVisible(false);
    }

  return (
    ((appStoreLink || googlePlayLink || installable) && visible) &&
    <div className='download-popup-container'>
        <div className='download-popup' onClick={() => setShow(true)}>
            <span className='download-close' onClick={handleClose}>
                <i className='icon-close'></i>
            </span>
            <div className='download-popup-title'>{app.localizationsItems?.application_modal_title}</div>
            <div className='download-popup-description'>{app.localizationsItems?.application_modal_content}</div>
        </div>
        {show && 
            <PopupModal 
                show={show} 
                setShow={setShow}
                appStoreLink={appStoreLink}
                googlePlayLink={googlePlayLink}
                deferredPrompt={deferredPrompt}
                setDeferredPrompt={setDeferredPrompt}
                installable={installable}
        />}
    </div>
  )
})

export default DownloadAppPopup